<template>
  <MainLayout :title="$t('personalData')" :short-title="$t('home')" back-to="/account" :has-toolbar="false">
    <template v-slot:header-right-mobile>
      <div>
        <button class="button is-text" @click="doSave">{{ $t('actions.save') }}</button>
      </div>
    </template>
    <template v-slot:menu>
      <AccountMenu />
    </template>
    <template v-slot:content>
      <form id="a" class="panel" @submit.prevent="doSave" @keyup.enter="doSave">
        <div class="panel-body">
          <b-field
            :label="$t('fullName')"
            :type="{ 'is-danger': $v.user.full_name.$error }"
            :message="$v.user.full_name.$error ? $t('required') : ''"
          >
            <b-input v-model="user.full_name" size="is-medium" type="text" name="name" />
          </b-field>

          <b-field
            :label="$t('address')"
            :type="{ 'is-danger': $v.user.address_line1.$error }"
            :message="$v.user.address_line1.$error ? $t('required') : ''"
          >
            <b-input v-model="user.address_line1" type="text" name="address_line1" />
          </b-field>
          <b-field :label="$t('address2')">
            <b-input v-model="user.address_line2" maxlength="200" type="text" name="address_line2" />
          </b-field>

          <b-field grouped>
            <b-field :label="$t('postalCode')">
              <b-input
                v-model="user.postal_code"
                size="is-width-small"
                maxlength="8"
                type="text"
                placeholder="0000-000"
                name="postal_code"
              />
            </b-field>
            <b-field :label="$t('locality')" expanded>
              <b-input v-model="user.locality" type="text" name="locality" />
            </b-field>
          </b-field>

          <b-field :label="$t('country')">
            <CountriesSelect :input="user.country" @update="updateCountry" />
          </b-field>

          <b-field grouped>
            <b-field :label="$t('phoneCountry')">
              <PhoneCountriesSelect :input="user.phone_country" @update="updatePhoneCountry" />
            </b-field>

            <b-field :label="$t('phone')" expanded>
              <b-input v-model="user.phone_number" type="text" maxlength="14" name="phone_number" />
            </b-field>
          </b-field>

          <b-field class="has-margin-bottom">
            <b-button type="is-primary" :loading="loadingSave" @click="doSave">{{ $t('actions.save') }}</b-button>
          </b-field>
        </div>
      </form>
    </template>
  </MainLayout>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

import MainLayout from '@/components/layouts/MainLayout'

import UserService from '@/services/UserService'
import AccountMenu from '@/components/menus/AccountMenu'
import CountriesSelect from '@/components/utilities/CountriesSelect'
import PhoneCountriesSelect from '@/components/utilities/PhoneCountriesSelect'

export default {
  components: {
    MainLayout,
    AccountMenu,
    CountriesSelect,
    PhoneCountriesSelect,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      loadingSave: false,
    }
  },

  validations: {
    user: {
      full_name: {
        required,
        min: minLength(2),
      },
      address_line1: {
        required,
        min: minLength(2),
      },
    },
  },

  methods: {
    updateCountry(value) {
      this.user.country = value
    },
    updatePhoneCountry(value) {
      this.user.phone_country = value
    },
    async doSave() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loadingSave = true
        const user = await UserService.update(this.user)
        if (user) {
          this.$store.dispatch('setUser', true, user)
          this.$buefy.snackbar.open({
            message: 'Os seus dados foram atualizados',
            type: 'is-primary',
            position: 'is-bottom-right',
            duration: 5000,
          })
          setTimeout(() => {
            this.loadingSave = false
          }, 250)
        }
      }
    },
  },
}
</script>
